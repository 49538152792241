import Vue from 'vue'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
//vue-sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

Vue.use(VueToast)
export default {
    toastrSuccess: function(message) {
      Vue.$toast.open({
        message: message,
        type: "success",
        duration: 3000,
        position: 'top-right',
        dismissible: true
      })    
    },
    toastrError: function(message) {
      Vue.$toast.open({
        message: message,
        type: "error",
        duration: 3000,
        position: 'top-right',
        dismissible: true
      })    
  },
    
    
  nameCheckConfirm: function (details) {
    const icontype = details.name == '' ? 'warning' : 'success';
    let htmltext = '';
    if (details.name == '') {
      htmltext = details.account+' is registered to: <br/><br/>'+'<strong>Name on Wallet Unavailable</strong>' +
      '<p>We are unable to confirm the name the number is registered to at this time. Proceed if you are certain the details are correct.</p>'
    } else {
      htmltext = details.account+' is registered to: <br/><br/>'+'<strong>'+details.name+'</strong>' +
      '<p>Do you want to proceed?</p>'
    }

    return Vue.swal.fire({
      title: details.provider +' - ('+ details.country +')',
      icon: icontype,
      html: htmltext,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg my-3',
        cancelButton: 'btn btn-outline-secondary btn-lg'
      },
      buttonsStyling: false,
      showCancelButton: true,
    })
  },
    
  loaderAlert: function () {
    return Vue.swal.fire({
      html: '<img src="'+require('@/assets/Ripple-200px.gif')+'"/><h3>Processing Payment</h3><br/><p class="mb-0">Please standby while we process your payment</p>',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false
    })
  },

  closeloaderalert: function () {
    Vue.swal.close();
  }, 

  invalidAlert: function (details) {
    const htmltext = details.account+' is invalid. <br><br>'+'<p>Please try again using another number.</p>'
    return Vue.swal.fire({
      title: details.provider +' - ('+ details.country +')',
      icon: 'error',
      html: htmltext,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn btn-primary btn-lg my-3',
        cancelButton: 'btn btn-outline-secondary btn-lg'
      },
      buttonsStyling: false,
      showCancelButton: false,
    })
  },
}




